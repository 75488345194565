body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Source Sans Pro', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: 'Source Sans Pro', sans-serif;
}

/* error styles */
.input-error {
  border: 1px solid red !important;
  border-radius: 3px;
}

/* confirm alert styles */
.react.confirm-alert {
  padding: 1em;
}

.react.confirm-alert-body,
.react-confirm-alert-body {
  width: 100% !important;
  max-width: 550px !important
  ;
}

.react-confirm-alert {
  margin: 1em;
}

.react-confirm-alert-button-group > button {
  background: #3689ff !important;
  border: 2px solid #3689ff !important;
  font-weight: 600;
  transition: all 0.2s ease-out;
}
.react-confirm-alert-button-group > button:hover {
  background: #fff !important;
  color: #3689ff !important;
  border: 2px solid #3689ff;
}
/* confirm alert styles */

/* video player styles */
.video-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* navody modal styles */
.modal {
  padding-left: 0 !important;
  padding-right: 0 !important;
  top: 0px !important;
}

.navody-modal {
  max-width: 100% !important;
  margin: 0 !important;
  width: 100%;
  height: 100%;
}
.navody-modal .modal-content {
  border: 0;
}

.modal-backdrop.show {
  opacity: 0 !important;
}
/* navody modal styles */

@media (max-width: 324px) {
  .modal {
    top: 0px !important;
  }
}

@media (max-width: 320px) {
  .react-confirm-alert {
    margin: 0.5em;
  }
  .react.confirm-alert-body,
  .react-confirm-alert-body {
    padding: 1em !important;
  }
}
